<template>
	<div class="mt-3">
		<b-form @submit.prevent="checkBag">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="1" autocomplete="off" ref="lm_or_hawm" @keydown.tab="checkBag" autofocus v-model="bag_code" placeholder="Enter bag code"></b-form-input>
					</div>
                </div>
            </div>
			<div v-if="bagdetails.bag_code" class="row">
				<div class="col-sm-7 m-auto">

					<table class="table table-bordered">
						<tr class="bg-light" v-if="bagdetails.bag_code"><th>Bag Code</th><td>{{bagdetails.bag_code}}</td></tr>
						<tr class="bg-light"><th>Total Weight</th><td>{{bagdetails.weight}} kg</td></tr>
						<tr class="bg-light"><th>No. of Items (LMs)</th><td>{{bagdetails.weight}}</td></tr>
						<tr class="bg-light"><th>Country</th><td>{{bagdetails.country}}</td></tr>
						<tr class="bg-light" v-if="bagdetails.custom_type"><th>Custom Type</th><td>{{bagdetails.custom_type}}</td></tr>
						<tr class="bg-light" v-if="bagdetails.clearance_type"><th>Clearance Type</th><td>{{bagdetails.clearance_type}}</td></tr>
					</table>

					<div class="text-center">
						<b-button variant="warning" @click="deleteBag">Delete Bag</b-button>
					</div>

				</div>
			</div>

		</b-form>
	</div>
</template>

<script>
export default {
	name: "DeleteBag",
	title: "Delete Bag",
	data() {
		return {
			bag_code: '',
			bagdetails: {},
			openBags: this.$store.getters.global.openBags || [],
			closedBags: this.$store.getters.global.closedBags || [],
		}
	},
	methods: {
		async checkBag() {
			try {
				this.bagdetails = this.openBags.find(i => i.bag_code == this.bag_code) || {}
				if(!this.bagdetails.bag_code) {
					this.popToast('Failed!', `Bagcode ${this.bag_code} not found.`, 'danger')
					this.bag_code = ''
				}
			} catch (err) {
				this.bag_code = ''
				console.error(err)
			}
		},
		async deleteBag() {
			let bags = this.openBags
			if(confirm(`Are you sure? Want to delete bag ${this.bag_code}.`)) {
				const i = bags.findIndex(i => i.bag_code == this.bag_code);
				bags.splice(i, 1)
				console.log(`Bag ${this.bag_code} has been deleted.`);
				this.$store.commit('setGlobal', {'openBags': bags})
				this.popToast('Deleted!', `Bag ${this.bag_code} has been deleted.`, 'success')
				this.$refs.lm_or_hawm.focus()
				this.bagdetails = {}
				this.bag_code = ''
			}
		}
	}
}
</script>